import React from 'react';
import './PesoSeco.css'; // Importamos los estilos que definiremos

function PesoSeco() {
  return (
	<div className="peso-seco-page">
	  {/* Header de la página con imagen de fondo */}
	  <header className="peso-seco-header">
		<h1 className="peso-seco-header-title">Peso Seco</h1>
	  </header>

	  {/* Sección de introducción */}
	  <section className="peso-seco-intro-section">
		<h1 className="peso-seco-title">¿Qué es el Peso Seco?</h1>
		<p className="peso-seco-intro-text">
		  El peso seco es el peso corporal de una persona después de que se ha eliminado todo el exceso de líquidos del cuerpo. Este peso representa el estado óptimo del cuerpo cuando no hay retención de líquidos, lo que permite que los órganos y tejidos funcionen correctamente. Es una medida crítica para pacientes que reciben tratamiento de hemodiálisis, ya que ayuda a determinar cuánta cantidad de líquido debe ser eliminada durante el procedimiento.
		</p>
	  </section>

	  {/* Sección de imagen */}
	  <section className="peso-seco-image-section">
		<div className="peso-seco-image-container">
		  <img src="/images/peso-seco-diagram.jpg" alt="Diagrama de Peso Seco" className="peso-seco-diagram-image" />
		</div>
	  </section>

	  {/* Sección de funciones del peso seco */}
	  <section className="peso-seco-functions-section">
		<h2 className="peso-seco-subtitle">¿Qué funciones tiene el Peso Seco?</h2>
		<p className="peso-seco-functions-text">
		  El peso seco es fundamental para el manejo adecuado de la hemodiálisis. Permite a los profesionales de la salud calcular la cantidad exacta de líquido que se debe retirar del cuerpo del paciente durante el tratamiento. Esto previene complicaciones como la sobrehidratación, que puede llevar a hipertensión y problemas cardíacos, o la deshidratación, que puede causar mareos, calambres y presión arterial baja.
		</p>
	  </section>

	  {/* Sección de cómo se obtiene el peso seco */}
	  <section className="peso-seco-how-section">
		<h2 className="peso-seco-subtitle">¿Cómo se obtiene el Peso Seco?</h2>
		<p className="peso-seco-how-text">
		  El peso seco se obtiene a través de una combinación de observación clínica y evaluación médica. Los médicos consideran la presión arterial, los niveles de hinchazón en el cuerpo, y otros signos de sobrecarga de líquidos o deshidratación. Se ajusta periódicamente para reflejar cambios en la salud del paciente y su composición corporal.
		</p>
	  </section>

	  {/* Sección de importancia del peso seco en la hemodiálisis */}
	  <section className="peso-seco-importance-section">
		<h2 className="peso-seco-subtitle">¿Por qué es importante el Peso Seco en la Hemodiálisis?</h2>
		<p className="peso-seco-importance-text">
		  Mantener el peso seco es vital para asegurar que la hemodiálisis sea eficaz y segura. Un peso seco preciso ayuda a evitar complicaciones graves como presión arterial inestable, malestar general y estrés en el sistema cardiovascular. Además, asegura que el cuerpo esté en equilibrio, facilitando una mejor calidad de vida y un tratamiento más efectivo.
		</p>
	  </section>

	  {/* Sección de preguntas frecuentes */}
	  <div className="hemodialisis-faq-title">
		  <h2 className="hemodialisis-subtitle">Preguntas Frecuentes</h2>
		</div>
	  
	 <section className="peso-seco-faq-section">
		

		<div className="peso-seco-faq-card">
		  <h3 className="peso-seco-faq-question">¿Con qué frecuencia se ajusta el peso seco?</h3>
		  <p className="peso-seco-faq-answer">
			El peso seco se ajusta de manera regular dependiendo de la condición del paciente, cambios en su dieta, y otros factores relacionados con su salud general. Por lo general, se revisa mensualmente o según las necesidades del paciente.
		  </p>
		</div>

		<div className="peso-seco-faq-card">
		  <h3 className="peso-seco-faq-question">¿Qué ocurre si no se alcanza el peso seco?</h3>
		  <p className="peso-seco-faq-answer">
			No alcanzar el peso seco puede resultar en retención de líquidos o deshidratación. Ambas condiciones pueden afectar negativamente la salud del paciente y aumentar el riesgo de complicaciones durante y después de la hemodiálisis.
		  </p>
		</div>

		<div className="peso-seco-faq-card">
		  <h3 className="peso-seco-faq-question">¿Cómo sé si estoy en mi peso seco?</h3>
		  <p className="peso-seco-faq-answer">
			Los profesionales de la salud evaluarán tu presión arterial, niveles de hinchazón y otros signos clínicos para determinar si estás en tu peso seco. Si tienes dudas, es importante que lo consultes con tu médico.
		  </p>
		</div>

		<div className="peso-seco-faq-card">
		  <h3 className="peso-seco-faq-question">¿Puede cambiar mi peso seco con el tiempo?</h3>
		  <p className="peso-seco-faq-answer">
			Sí, el peso seco puede cambiar debido a variaciones en la dieta, pérdida o ganancia de peso, y cambios en la salud general. Por eso es crucial monitorearlo y ajustarlo regularmente.
		  </p>
		</div>

		<div className="peso-seco-faq-card">
		  <h3 className="peso-seco-faq-question">¿Cómo afecta el peso seco a la presión arterial?</h3>
		  <p className="peso-seco-faq-answer">
			Un peso seco incorrecto puede causar presión arterial inestable. Si hay retención de líquidos, la presión arterial puede aumentar; si se elimina demasiado líquido, puede bajar peligrosamente, provocando mareos o fatiga.
		  </p>
		</div>

		<div className="peso-seco-faq-card">
		  <h3 className="peso-seco-faq-question">¿Qué papel juega el equipo médico en el manejo del peso seco?</h3>
		  <p className="peso-seco-faq-answer">
			El equipo médico monitorea de cerca el peso seco y realiza ajustes según las necesidades del paciente. Esto incluye la revisión de signos de hinchazón, presión arterial y otros indicadores.
		  </p>
		</div>

		<div className="peso-seco-faq-card">
		  <h3 className="peso-seco-faq-question">¿El peso seco varía entre sesiones de hemodiálisis?</h3>
		  <p className="peso-seco-faq-answer">
			El peso seco no debería variar significativamente entre sesiones. Sin embargo, cambios en la dieta, medicación o estado de salud pueden hacer que varíe a lo largo del tiempo.
		  </p>
		</div>

		<div className="peso-seco-faq-card">
		  <h3 className="peso-seco-faq-question">¿Qué síntomas indican que no estoy en mi peso seco?</h3>
		  <p className="peso-seco-faq-answer">
			Los síntomas de retención de líquidos incluyen hinchazón, aumento de peso y dificultad para respirar. Por otro lado, la deshidratación puede causar mareos, calambres y fatiga.
		  </p>
		</div>

		<div className="peso-seco-faq-card">
		  <h3 className="peso-seco-faq-question">¿Es seguro ajustar mi peso seco sin consultar al médico?</h3>
		  <p className="peso-seco-faq-answer">
			No, cualquier ajuste en el peso seco debe ser realizado por un profesional de la salud. Cambios no supervisados pueden tener consecuencias graves para tu salud.
		  </p>
		</div>
	  </section>
	</div>
  );
}

export default PesoSeco;