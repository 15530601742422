import React, { useState } from 'react';
import './Instalaciones.css';

// Importa las imágenes de la carpeta de instalaciones
import img1 from '../images/instalaciones/1.jpg';
import img2 from '../images/instalaciones/2.jpg';
import img3 from '../images/instalaciones/3.jpg';
import img4 from '../images/instalaciones/4.jpg';
import img5 from '../images/instalaciones/5.jpg';
import img6 from '../images/instalaciones/6.jpg';
import img7 from '../images/instalaciones/7.jpg';
import img8 from '../images/instalaciones/8.jpg';
import img9 from '../images/instalaciones/9.jpg';
import img10 from '../images/instalaciones/10.jpg';
import img11 from '../images/instalaciones/11.jpg';
import img12 from '../images/instalaciones/12.jpg';
import img13 from '../images/instalaciones/13.jpg';
import img14 from '../images/instalaciones/14.jpg';
import img15 from '../images/instalaciones/15.jpg';

function Instalaciones() {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (image) => {
	setSelectedImage(image);
  };

  const closeImage = () => {
	setSelectedImage(null);
  };

  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15];

  return (
	<div className="instalaciones-page">
	  {/* Header con título */}
	  <header className="instalaciones-header">
		<h1 className="header-title">Nuestras Instalaciones</h1>
	  </header>

	  {/* Galería de imágenes */}
	  <section className="gallery-section">
		<div className="gallery">
		  {images.map((image, index) => (
			<div className="gallery-item" key={index} onClick={() => openImage(image)}>
			  <img src={image} alt={`Instalación ${index + 1}`} className="gallery-image" />
			</div>
		  ))}
		</div>
	  </section>

	  {/* Modal para la imagen seleccionada */}
	  {selectedImage && (
		<div className="overlay" onClick={closeImage}>
		  <div className="overlay-content">
			<img src={selectedImage} alt="Imagen en grande" className="large-image" />
			<button className="close-btn" onClick={closeImage}>&times;</button>
		  </div>
		</div>
	  )}
	</div>
  );
}

export default Instalaciones;