import React from 'react';
import './Hemodialisis.css'; // Importamos los estilos que definiremos
import diagramaHemo from '../images/diagrama-hemo.jpeg'; 

function Hemodialisis() {
  return (
	<div className="hemodialisis-page">
	  {/* Header de la página con imagen de fondo */}
	  <header className="hemodialisis-header">
		<h1 className="hemodialisis-header-title">Hemodiálisis</h1>
	  </header>

	  {/* Sección de introducción */}
	  <section className="hemodialisis-intro-section">
		<h1 className="hemodialisis-title">¿Qué es la Hemodiálisis?</h1>
		<p className="hemodialisis-intro-text">
		  La hemodiálisis es un tratamiento médico crucial para pacientes cuyos riñones ya no son capaces de filtrar adecuadamente los desechos y el exceso de líquidos en el cuerpo. Este tratamiento utiliza una máquina especial llamada dializador, que actúa como un riñón artificial para limpiar la sangre de toxinas, productos de desecho y exceso de agua.
		  <br /><br />
		  Además de eliminar los desechos del cuerpo, la hemodiálisis regula los niveles de electrolitos como el sodio y el potasio, y ayuda a mantener la presión arterial dentro de niveles saludables. El procedimiento generalmente se lleva a cabo en sesiones de 3 a 5 horas, varias veces por semana, dependiendo de las necesidades de cada paciente. Es un tratamiento vital para personas con insuficiencia renal crónica o aguda, que requieren un reemplazo temporal o permanente de la función renal.
		</p>
	  </section>

	  {/* Sección para el diagrama */}
	  <section className="hemodialisis-diagram-section">
		<h2 className="hemodialisis-subtitle">Diagrama del Proceso de Hemodiálisis</h2>
		<div className="hemodialisis-diagram-container">
		  <img src={diagramaHemo} alt="Diagrama de Hemodiálisis" className="hemodialisis-diagram-image" />
		</div>
	  </section>

	  {/* Sección para qué sirve */}
	  <section className="hemodialisis-purpose-section">
		<h2 className="hemodialisis-subtitle">¿Para qué sirve la Hemodiálisis?</h2>
		<p className="hemodialisis-purpose-text">
		  La hemodiálisis es esencial para personas que padecen insuficiencia renal crónica o daño renal grave. Su propósito es suplir la función de los riñones, permitiendo que el paciente mantenga una mejor calidad de vida. El procedimiento, llevado a cabo varias veces por semana, elimina las toxinas del cuerpo, regula los niveles de sal y minerales, y ayuda a controlar la presión arterial.
		</p>
	  </section>

	  {/* Sección quiénes necesitan la hemodiálisis */}
	  <section className="hemodialisis-who-section">
		<h2 className="hemodialisis-subtitle">¿Quiénes necesitan Hemodiálisis?</h2>
		<p className="hemodialisis-who-text">
		  La hemodiálisis es recomendada para personas con insuficiencia renal en etapa terminal, o para quienes han perdido entre el 85% y 90% de la función de sus riñones. También puede ser una solución temporal para pacientes que sufren de insuficiencia renal aguda. Nuestros especialistas te acompañarán en cada etapa del tratamiento para asegurar que se adapte a tus necesidades.
		</p>
	  </section>

	  {/* Sección de preguntas frecuentes */}
	  <div className="hemodialisis-faq-title">
		<h2 className="hemodialisis-subtitle">Preguntas Frecuentes</h2>
	  </div>
	  <section className="hemodialisis-faq-section">
		<div className="hemodialisis-faq">
		  <h3 className="hemodialisis-faq-question">¿Cuánto tiempo dura una sesión de hemodiálisis?</h3>
		  <p className="hemodialisis-faq-answer">
			Generalmente, cada sesión de hemodiálisis dura entre 3 y 5 horas y se realiza alrededor de tres veces por semana, dependiendo del estado de salud de cada paciente.
		  </p>
		</div>

		<div className="hemodialisis-faq">
		  <h3 className="hemodialisis-faq-question">¿La hemodiálisis es dolorosa?</h3>
		  <p className="hemodialisis-faq-answer">
			La mayoría de los pacientes experimentan molestias al insertar las agujas, pero el tratamiento en sí no debe causar dolor. Algunos pacientes pueden sentir fatiga o calambres musculares.
		  </p>
		</div>

		<div className="hemodialisis-faq">
		  <h3 className="hemodialisis-faq-question">¿Qué debo hacer antes de una sesión de hemodiálisis?</h3>
		  <p className="hemodialisis-faq-answer">
			Es recomendable evitar comidas pesadas antes de la sesión y seguir las recomendaciones del equipo médico sobre la medicación y la ingesta de líquidos.
		  </p>
		</div>

		<div className="hemodialisis-faq">
		  <h3 className="hemodialisis-faq-question">¿Puedo llevar una vida normal mientras recibo hemodiálisis?</h3>
		  <p className="hemodialisis-faq-answer">
			Muchas personas continúan trabajando y llevando una vida activa durante el tratamiento de hemodiálisis. Sin embargo, es necesario seguir cuidadosamente las indicaciones médicas y ajustar la dieta y la rutina diaria para adaptarse al tratamiento.
		  </p>
		</div>

		<div className="hemodialisis-faq">
		  <h3 className="hemodialisis-faq-question">¿Cuáles son los riesgos asociados con la hemodiálisis?</h3>
		  <p className="hemodialisis-faq-answer">
			La hemodiálisis es un procedimiento seguro, pero como cualquier tratamiento médico, puede tener riesgos. Entre ellos se incluyen infecciones en el acceso vascular, hipotensión (baja presión arterial) y calambres musculares. Nuestro equipo médico está capacitado para minimizar estos riesgos y garantizar tu seguridad.
		  </p>
		</div>

		<div className="hemodialisis-faq">
		  <h3 className="hemodialisis-faq-question">¿Qué tipo de dieta debo seguir durante la hemodiálisis?</h3>
		  <p className="hemodialisis-faq-answer">
			Es importante seguir una dieta especial baja en sodio, potasio y fósforo. Los pacientes deben evitar alimentos ricos en sal, frutas como plátanos y naranjas, y productos lácteos en exceso.
		  </p>
		</div>

		<div className="hemodialisis-faq">
		  <h3 className="hemodialisis-faq-question">¿Puedo viajar si recibo tratamiento de hemodiálisis?</h3>
		  <p className="hemodialisis-faq-answer">
			Sí, es posible viajar, pero se deben hacer arreglos previos para recibir el tratamiento en un centro de hemodiálisis en el destino. Existen clínicas especializadas que pueden recibir pacientes de forma temporal.
		  </p>
		</div>

		<div className="hemodialisis-faq">
		  <h3 className="hemodialisis-faq-question">¿Cuánto tiempo puedo vivir con hemodiálisis?</h3>
		  <p className="hemodialisis-faq-answer">
			Con el tratamiento adecuado, las personas pueden vivir muchos años con hemodiálisis. La calidad de vida depende de factores como la salud general del paciente, el cumplimiento del tratamiento y el apoyo médico.
		  </p>
		</div>
	  </section>
	</div>
  );
}

export default Hemodialisis;
