import React from 'react';
import './Servicios.css';
import hemodialisisImg from '../images/hemodialisis.jpg';
import laboratorioImg from '../images/laboratorio.jpg';
import imagenologiaImg from '../images/imagenologia.jpg';
import consultaImg from '../images/consulta.jpg';
import nutricionImg from '../images/nutricion.jpg';
import densitometriaImg from '../images/densitometria.jpg';
import cafeteriaImg from '../images/cafeteria.jpg';
import videoSource from '../videos/servicios.mp4';

function Servicios() {
  return (
	<div className="servicios-page">
	  <header className="servicios-header">
		<div className="video-background">
		  <video className="background-video" autoPlay loop muted>
			<source src={videoSource} type="video/mp4" />
			Tu navegador no soporta videos HTML5.
		  </video>
		</div>
		<h1 className="servicios-header-title">Nuestros Servicios</h1>
	  </header>

	  {/* Sección de Hemodiálisis */}
	  <section className="servicio-section">
		<div className="servicio-content">
		  <h2 className="servicio-title">Hemodiálisis</h2>
		  <p className="servicio-text">
			La hemodiálisis es un procedimiento vital para aquellos pacientes que requieren apoyo en la eliminación de toxinas y exceso de líquidos del organismo debido a insuficiencia renal. En Cendial, ofrecemos un entorno cómodo y seguro, con tecnología de punta y un equipo de especialistas altamente capacitados que te acompañan en cada sesión, asegurando una experiencia lo más confortable posible. Nos enfocamos en tu bienestar integral, brindando un cuidado personalizado para que mantengas una buena calidad de vida.
		  </p>
		</div>
		<div className="servicio-image-container">
		  <img src={hemodialisisImg} alt="Hemodiálisis" className="servicio-image" />
		</div>
	  </section>

	  {/* Sección de Análisis Clínicos */}
	  <section className="servicio-section reverse">
		<div className="servicio-content">
		  <h2 className="servicio-title">Análisis Clínicos de Laboratorio</h2>
		  <p className="servicio-text">
			Nuestro laboratorio está equipado con tecnología de última generación para realizar una amplia variedad de análisis clínicos. Desde estudios de rutina hasta pruebas más especializadas, garantizamos resultados precisos y confiables. Nuestro personal técnico y médico se compromete a proporcionarte un servicio rápido y eficiente, porque entendemos que tu tiempo y tu salud son valiosos. Ven a Cendial y experimenta un servicio de laboratorio que combina precisión, agilidad y trato humano.
		  </p>
		</div>
		<div className="servicio-image-container">
		  <img src={laboratorioImg} alt="Análisis Clínicos" className="servicio-image" />
		</div>
	  </section>

	  {/* Sección de Imagenología */}
	  <section className="servicio-section">
		<div className="servicio-content">
		  <h2 className="servicio-title">Estudios de Imagenología</h2>
		  <p className="servicio-text">
			En Cendial, contamos con equipos avanzados de imagenología que permiten un diagnóstico preciso y oportuno. Realizamos estudios como ultrasonidos, tomografías y rayos X, siempre con la asistencia de técnicos especializados y radiólogos expertos. Nuestro compromiso es ofrecerte un servicio integral y profesional, cuidando cada detalle para que te sientas en un ambiente seguro y confiable. La precisión en el diagnóstico es clave, y estamos aquí para proporcionarte los mejores resultados.
		  </p>
		</div>
		<div className="servicio-image-container">
		  <img src={imagenologiaImg} alt="Imagenología" className="servicio-image" />
		</div>
	  </section>

	  {/* Sección de Consulta Médica */}
	  <section className="servicio-section reverse">
		<div className="servicio-content">
		  <h2 className="servicio-title">Consulta Médica</h2>
		  <p className="servicio-text">
			Ofrecemos consultas médicas con un enfoque integral y personalizado. Nuestros médicos están comprometidos en brindarte una atención detallada, escuchando tus necesidades y ofreciendo diagnósticos precisos. Ya sea para un chequeo de rutina o una consulta especializada, en Cendial nos enfocamos en que te sientas atendido y comprendido. Ven y descubre un servicio médico que prioriza tu salud y bienestar en todo momento.
		  </p>
		</div>
		<div className="servicio-image-container">
		  <img src={consultaImg} alt="Consulta Médica" className="servicio-image" />
		</div>
	  </section>

	  {/* Sección de Nutrición */}
	  <section className="servicio-section">
		<div className="servicio-content">
		  <h2 className="servicio-title">Nutrición</h2>
		  <p className="servicio-text">
			Sabemos que una alimentación adecuada es esencial para mantener una buena salud, por eso en Cendial contamos con servicios de nutrición adaptados a tus necesidades. Nuestros nutricionistas trabajan contigo para diseñar planes alimenticios que se ajusten a tus objetivos y condiciones de salud. Ya sea para mejorar tu estado físico, apoyar un tratamiento médico o simplemente llevar un estilo de vida saludable, estamos aquí para acompañarte en cada paso.
		  </p>
		</div>
		<div className="servicio-image-container">
		  <img src={nutricionImg} alt="Nutrición" className="servicio-image" />
		</div>
	  </section>

	  {/* Sección de Densitometría */}
	  <section className="servicio-section reverse">
		<div className="servicio-content">
		  <h2 className="servicio-title">Densitometría</h2>
		  <p className="servicio-text">
			La densitometría es un estudio crucial para evaluar la salud de tus huesos y prevenir condiciones como la osteoporosis. En Cendial, realizamos densitometrías con equipos de alta precisión y un equipo técnico que garantiza un procedimiento seguro y rápido. Mantener la fortaleza ósea es fundamental para tu bienestar, y nuestro objetivo es ayudarte a detectar y prevenir cualquier problema a tiempo.
		  </p>
		</div>
		<div className="servicio-image-container">
		  <img src={densitometriaImg} alt="Densitometría" className="servicio-image" />
		</div>
	  </section>

	  {/* Sección de Cafetería */}
	  <section className="servicio-section">
		<div className="servicio-content">
		  <h2 className="servicio-title">Cafetería</h2>
		  <p className="servicio-text">
			Nuestra cafetería es un espacio diseñado para ofrecerte un momento de relajación y disfrute mientras esperas o acompañas a un ser querido en su consulta o tratamiento. Ofrecemos café de excelente calidad y una selección de panes y refrigerios para que te sientas cómodo y bien atendido. En Cendial, creemos en cuidar de cada detalle para hacer tu experiencia lo más placentera posible.
		  </p>
		</div>
		<div className="servicio-image-container">
		  <img src={cafeteriaImg} alt="Cafetería" className="servicio-image" />
		</div>
	  </section>
	</div>
  );
}

export default Servicios;