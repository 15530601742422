import React from 'react';
import './Contacto.css'; // Importa los estilos de la página de contacto
import contactoVideo from '../videos/contacto-header.mp4'; // Importa el video de fondo

function Contacto() {
  return (
	<div className="contacto-page">
	  {/* Header de la página con video de fondo */}
	  <header className="contacto-header">
		<div className="video-background">
		  <video className="background-video" autoPlay loop muted>
			<source src={contactoVideo} type="video/mp4" />
			Tu navegador no soporta videos HTML5.
		  </video>
		</div>
		<h1 className="header-title">Contáctanos</h1>
	  </header>

	  {/* Sección de información de contacto */}
	  <section className="info-section">
		<h2>Información de Contacto</h2>
		<p>En Cendial, estamos aquí para cuidar de ti. Sabemos que cada pregunta, cada consulta y cada detalle cuentan, y queremos que sientas la confianza de acercarte a nosotros en todo momento. Nuestro equipo está siempre dispuesto a escucharte y apoyarte en lo que necesites, porque tu bienestar es lo más importante para nosotros.
		<br/><br/>
		Si tienes dudas, necesitas orientación o deseas conocer más sobre nuestros servicios, no dudes en contactarnos. ¡Será un placer acompañarte en tu camino hacia una mejor salud! Usa cualquiera de los medios que te dejamos abajo, y con gusto responderemos a tus inquietudes.</p>
		<ul className="contact-list">
		  <li><strong>Teléfono:</strong> <a href="tel:+523331620414" className="contact-btn">33 3162 0414</a></li>
		  <li><strong>Email:</strong> <a href="mailto:ventas@cendial.com.mx" className="contact-btn">ventas@cendial.com.mx</a></li>
		  <li><strong>Dirección:</strong> Av Enrique Díaz de León Sur 830, Moderna, 44190 Guadalajara, Jal.</li>
		  <li><strong>Horario:</strong> Lunes a Viernes 8:00 AM - 8:00 PM, Sábado 8:00 AM - 2:00 PM, Domingo cerrado.</li>
		</ul>
	  </section>

	  {/* Sección de formulario de contacto */}
	  <section className="form-section">
		<h2>Envíanos un Mensaje</h2>
		<form className="contact-form">
		  <div className="form-group">
			<label htmlFor="name">Nombre</label>
			<input type="text" id="name" name="name" placeholder="Tu nombre" required />
		  </div>
		  <div className="form-group">
			<label htmlFor="email">Email</label>
			<input type="email" id="email" name="email" placeholder="Tu email" required />
		  </div>
		  <div className="form-group">
			<label htmlFor="message">Mensaje</label>
			<textarea id="message" name="message" rows="5" placeholder="Tu mensaje" required></textarea>
		  </div>
		  <button type="submit" className="submit-btn">Enviar Mensaje</button>
		</form>
	  </section>

	  {/* Sección de mapa */}
	  <section className="map-section">
		<h2>Encuéntranos Aquí</h2>
		<div className="map-container">
		  <iframe
			title="Ubicación de Cendial"
			src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.906209969256!2d-103.35150432524511!3d20.669729102857567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b227ce1ad5c7%3A0x3d0900e1598de1f9!2sEnrique%20D%C3%ADaz%20de%20Le%C3%B3n%20Sur%20830%2C%20Moderna%2C%2044190%20Guadalajara%2C%20Jal.%2C%20M%C3%A9xico!5e0!3m2!1ses-419!2sus!4v1691535132444"
			width="100%"
			height="450"
			style={{ border: 0 }}
			allowFullScreen=""
			loading="lazy"
		  ></iframe>
		</div>
	  </section>
	</div>
  );
}

export default Contacto;