import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Footer from './components/Footer'; // Importa el Footer
import Index from './pages/index'; // Página de inicio
import Hemodialisis from './pages/Hemodialisis'; // Página de hemodiálisis
import Cendial from './pages/Cendial'; // Página de ¿Qué es Cendial?
import PesoSeco from './pages/PesoSeco'; // Página de Peso Seco
import Laboratorio from './pages/Laboratorio'; // Página de Laboratorio
import Imagen from './pages/Imagen'; // Página de Imagen
import Servicios from './pages/Servicios'; // Página de Servicios
import Contacto from './pages/Contacto'; // Página de Contacto
import Instalaciones from './pages/Instalaciones'; // Página de Instalaciones

function App() {
  return (
    <Router>
      <div className="App">
        <Dashboard />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/cendial" element={<Cendial />} />
          <Route path="/hemodialisis" element={<Hemodialisis />} />
          <Route path="/peso-seco" element={<PesoSeco />} />
          <Route path="/laboratorio" element={<Laboratorio />} />
          <Route path="/imagen" element={<Imagen />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/instalaciones" element={<Instalaciones />} /> {/* Nueva ruta para Instalaciones */}
        </Routes>
        <Footer /> {/* Agrega el Footer al final */}
      </div>
    </Router>
  );
}

export default App;