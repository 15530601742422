import React from 'react';
import './Imagen.css';

function Imagen() {
  return (
	<div className="imagen-page">
	  <div className="content-container">
		<h1 className="title">Estudios de Imagen</h1>
		<p className="message">
		  Esta sección está en desarrollo. Estamos trabajando para ofrecerte información detallada sobre nuestros estudios de imagen. ¡Pronto estará disponible!
		</p>
	  </div>
	</div>
  );
}

export default Imagen;