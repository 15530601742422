import React, { useState } from 'react';
import './Dashboard.css'; // Asegúrate de importar los estilos correctos
import logo from '../images/cendial-logo.png'; // Importa el logo correctamente
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faTint, faWeight, faVial, faXRay, faHandsHelping, faPhoneAlt, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

function Dashboard() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDashboard = () => {
	setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
	setIsOpen(false);
  };

  return (
	<>
	  {/* Contenedor del dashboard */}
	  <div className={`dashboard-container ${isOpen ? 'open' : ''}`}>
		<button className="hamburger-btn" onClick={toggleDashboard}>
		  {/* Cambiamos entre el ícono de hamburguesa y el ícono de "X" */}
		  <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
		</button>

		{/* Logo de Cendial, visible solo cuando el dashboard está abierto */}
		{isOpen && (
		  <div className="dashboard-header">
			<img src={logo} alt="Cendial Logo" className="cendial-logo" />
		  </div>
		)}

		{/* Contenido del dashboard */}
		<div className="dashboard-content">
		  <ul>
			<li>
			  <Link to="/" onClick={handleLinkClick}>
				<FontAwesomeIcon icon={faBuilding} className="menu-icon" />
				Inicio
			  </Link>
			</li>
			<li>
			  <Link to="/hemodialisis" onClick={handleLinkClick}>
				<FontAwesomeIcon icon={faTint} className="menu-icon" />
				Hemodiálisis
			  </Link>
			</li>
			<li>
			  <Link to="/peso-seco" onClick={handleLinkClick}>
				<FontAwesomeIcon icon={faWeight} className="menu-icon" />
				Peso Seco
			  </Link>
			</li>
			<li>
			  <Link to="/laboratorio" onClick={handleLinkClick}>
				<FontAwesomeIcon icon={faVial} className="menu-icon" />
				Estudios de Laboratorio
			  </Link>
			</li>
			<li>
			  <Link to="/imagen" onClick={handleLinkClick}>
				<FontAwesomeIcon icon={faXRay} className="menu-icon" />
				Estudios de Imagen
			  </Link>
			</li>
			<li>
			  <Link to="/instalaciones" onClick={handleLinkClick}>
				<FontAwesomeIcon icon={faBuilding} className="menu-icon" />
				Instalaciones
			  </Link>
			</li>
			<li>
			  <Link to="/servicios" onClick={handleLinkClick}>
				<FontAwesomeIcon icon={faHandsHelping} className="menu-icon" />
				Servicios
			  </Link>
			</li>
			<li>
			  <Link to="/contacto" onClick={handleLinkClick}>
				<FontAwesomeIcon icon={faPhoneAlt} className="menu-icon" />
				Contacto
			  </Link>
			</li>
		  </ul>

		  {/* Redes sociales */}
		  <div className="social-media">
			<a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
			  <FontAwesomeIcon icon={faFacebook} className="social-icon" />
			</a>
			<a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
			  <FontAwesomeIcon icon={faInstagram} className="social-icon" />
			</a>
			<a href="https://www.tiktok.com/" target="_blank" rel="noopener noreferrer">
			  <FontAwesomeIcon icon={faTiktok} className="social-icon" />
			</a>
		  </div>

		  {/* Botón llamativo de Consulta de Resultados */}
		  <div className="consulta-btn-container">
			<a href="https://cendial.pasteur.com.mx/" target="_blank" rel="noopener noreferrer" className="consulta-btn">
			  Consulta de Resultados
			</a>
		  </div>
		</div>
	  </div>
	</>
  );
}

export default Dashboard;