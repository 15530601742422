import React from 'react';
import './index.css'; // Importa los estilos que vamos a crear
import logo from '../images/cendial-logo.png'; // Logo de Cendial
import videoSource from '../videos/dialisis.mp4'; // Video de fondo

function Index() {
  return (
	<div className="index-page">
	  {/* Contenido de la página de inicio con margen para no solaparse con el dashboard */}
	  <div className="index-container">
		{/* Sección de bienvenida */}
		<div className="welcome-section">
		  <div className="video-background">
			<video className="background-video" autoPlay loop muted>
			  <source src={videoSource} type="video/mp4" />
			  Tu navegador no soporta videos HTML5.
			</video>
		  </div>

		  <div className="welcome-content">
			<div className="logo-container">
			  <img src={logo} alt="Cendial Logo" className="welcome-logo" />
			</div>
			<h1 className="welcome-title">Tu bienestar es nuestro compromiso.</h1>
			<p className="welcome-subtitle">Explora todo lo que Cendial tiene para ofrecerte, desde consultas hasta análisis clínicos avanzados.</p>
			<a className="explore-btn" href="tel:+523318014219">Agenda tu cita</a> {/* Función de llamada */}
		  </div>
		</div>

		{/* Resumen de Cendial */}
		<section id="about" className="about-section">
		  <h2>¿Qué es Cendial?</h2>
		  <p>
			Cendial es un centro médico especializado en brindar soluciones avanzadas de diagnóstico y tratamiento, con un enfoque particular en la atención integral de pacientes que requieren hemodiálisis. Con un equipo de especialistas comprometidos y tecnología de última generación, nuestro objetivo principal es mejorar la calidad de vida de las personas que enfrentan enfermedades renales. Nos dedicamos a ofrecer un servicio preciso, humano y confiable, especialmente en el manejo y cuidado continuo de pacientes con insuficiencia renal crónica, asegurando tratamientos personalizados y eficientes.
			<br/><br/>
			En Cendial, creemos que la hemodiálisis no es solo un tratamiento, sino un proceso de cuidado continuo en el que el bienestar físico y emocional del paciente son nuestra prioridad. Por eso, nuestras instalaciones están diseñadas para proporcionar un ambiente cómodo y seguro, donde cada paciente recibe la atención y el soporte que necesita para mantener una vida plena.
			<br/><br/>
			Además de la hemodiálisis, ofrecemos una amplia gama de estudios de laboratorio que complementan el diagnóstico y monitoreo de nuestros pacientes. Nuestro compromiso con la excelencia médica y el bienestar de quienes confían en nosotros es lo que nos define.
		  </p>
		</section>

		{/* Botones para consulta de resultados */}
		<section className="cta-section">
		  <h3>Consulta de Resultados</h3>
		  <a className="cta-btn" href="https://cendial.pasteur.com.mx/" target="_blank" rel="noopener noreferrer">
			Consulta de Resultados
		  </a>
		</section>

		{/* Misión y Visión */}
		<section className="mission-vision">
		  <div className="mission">
			<h3>Misión</h3>
			<p>
			  Brindar servicios de diagnóstico confiables y de alta precisión, asegurando siempre la salud y el bienestar de nuestros pacientes.
			</p>
		  </div>
		  <div className="vision">
			<h3>Visión</h3>
			<p>
			  Ser el centro de diagnóstico líder en la región, reconocido por la calidad y confiabilidad de nuestros servicios, así como por nuestro compromiso con los pacientes.
			</p>
		  </div>
		</section>
	  </div>
	</div>
  );
}

export default Index;