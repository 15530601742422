import React from 'react';
import './Laboratorio.css';

function Laboratorio() {
  return (
	<div className="laboratorio-page">
	  <div className="content-container">
		<h1 className="title">Estudios de Laboratorio</h1>
		<p className="message">
		  Esta sección está en desarrollo. Estamos trabajando para ofrecerte información detallada sobre nuestros estudios de laboratorio. ¡Pronto estará disponible!
		</p>
	  </div>
	</div>
  );
}

export default Laboratorio;